import {Button} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {connect} from "react-redux";
import {resetContinue} from "../../../Redux/relations/relationSlice";
import {useCurrentPosition} from "../Helpers/stepOrder";
import {useDispatch} from "react-redux";
import {useUpdateUserMutation} from "../../../Redux/api/apiSlice";
import {useNavigate} from "react-router-dom";

function BackContinueButtons(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {isFirst, isLast, slug} = useCurrentPosition();
    const {loading, disabled, currLocation, token, usersObj} = props;
    const renderContinue = ((isLast || (slug.path === "submission-page") || (slug.path === "finished-questionnaire")) || disabled)
    const renderSaveExit = renderContinue || isFirst || loading;
    const [updateUser] = useUpdateUserMutation();

    if (currLocation !== "relations-histories" && disabled) {
        dispatch(resetContinue())
    }

    const updateUserReturnLater = async () => {
        // redux entity is not extensible, so must define temporary variable
        let usersUpdateObj = {
            ...usersObj, // spread object
            dob: new Date(usersObj.dob + "T00:00:00.000Z"), // add time to date
            token: token
        }
        await updateUser(usersUpdateObj)
    }

    return (
        <Grid container justifyContent={"center"}
              size={12} backgroundColor={"#F4FAFE"}
              className="questionnaire-footer">
            <Grid size={4} alignSelf={isFirst ? "" : "center"}>
                {isFirst ? undefined : (
                    <Button variant={"outlined"}
                            type="submit"
                            name="back"
                            id="back-questionnaire"
                            sx={{marginLeft: "auto", display: `${loading ? "none" : "block"}`}}>
                        <span className="backcontinue-button-text"
                              id="back-questionnaire-text">Back</span>
                    </Button>
                )}
            </Grid>
            <Grid size={4} alignSelf={"center"}>
                <Button variant={"outlined"} id={"save-exit-questionnaire"}
                        onClick={(e) => {
                            e.preventDefault();
                            updateUserReturnLater();
                            navigate("/");
                        }}
                        sx={{marginLeft: "auto", marginRight: "16px", display: `${renderSaveExit ? "none" : "block"}`}}>
                    <span className="backcontinue-button-text">
                        Save and Exit
                    </span>
                </Button>
            </Grid>
            <Grid size={4} alignSelf={renderContinue ? "" : "center"}>
                {renderContinue ? undefined : (
                    <Button variant={"contained"}
                            className={(loading ? " button-loading" : "")}
                            type="submit"
                            name="next"
                            id="next-questionnaire">
                        <span className="backcontinue-button-text"
                              id="next-questionnaire-text">Continue</span>
                    </Button>
                )}
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => {
    return {
        disabled: (state.relations.relationsCardIsExpanded && state.users.usersLocation.url === "relations-histories"),
        currLocation: state.users.usersLocation.url,
        usersObj: state.users.entities[state.users.usersPatientId],
        token: state.users.usersHasToken,
    }
};

export default connect(mapStateToProps)(BackContinueButtons);