import {Card, CardMedia, Grid, Typography} from "@mui/material";
import React from "react";

import "../Styles/Components/NavigationCardWide.css";

import FamilyTree from "../Resources/Images/DNAA/family-tree-dnaa.svg";

const NavigationCardWide = (props) => {
  let {title, content, imageType} =
    props;
  let image;
  switch (imageType) {
    case "diary":
      image = FamilyTree;
      break;
    default:
      image = undefined;
  }
  return (
    <Grid container spacing={1} className="teal-gradient">
      <Grid item xs={12} sm={5} sx={{paddingLeft: 0, paddingRight: 0}}>
        <Card sx={{backgroundColor: "transparent", boxShadow: "unset", borderRadius: "unset"}}>
          <CardMedia component="img"
                     image={image}
                     alt="family tree"
                     title={"family-tree"}
                     sx={{
                       marginTop: "-25px",
                       maxWidth: {xs: "250px", sm: "400px"},
                       maxHeight: {xs: "270px"},
                       ml: {xs: "auto", sm: "unset", lg: 12, xl: 29},
                       mr: {xs: "auto", sm: "unset"}
                     }} />
        </Card>
      </Grid>
      <Grid item xs={12} sm={7} mt={{xs: 0, sm: 5}}>
        <Typography variant={"h2"}
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "2.5rem",
                      textAlign: {xs: "center", sm: "left"},
                    }}>{title}</Typography>
        <Typography variant={"body1"}
                    sx={{
                      color: "white",
                      fontSize: "1rem",
                      mt: 2.5,
                      mb: 2.5,
                      ml: {xs: 5, sm: "unset"},
                      mr: {xs: 5, md: 25, lg: 45, xl: 60},
                      textAlign: {xs: "center", sm: "left"},
                    }}>{content}</Typography>
      </Grid>
    </Grid>
  );
};

export default NavigationCardWide;
