// noinspection JSUnresolvedVariable

import {
    Button, Card,
    CardHeader, Collapse,
    Grid, IconButton,
    Tooltip, Typography,
} from "@mui/material";

import React, {useContext} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    addRelationsToDb,
    disableContinueButton,
    fetchRelations
} from "../../../Redux/relations/relationSlice";
import {getRelationCardLabel, relationsAndRelations} from "../Helpers/relationsMap";
import {FamilyTreeList} from "../Context/TreeList_BuildListContext";
import RelationConditions from "./relationCardConditionsCollapsible";
import RelationEdit from "./relationCardInnerContent";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AddIcon from '@mui/icons-material/Add';

// noinspection JSUnresolvedFunction
const reqPedigreeIcons = require.context("../../../Resources/Images/Pedigree", true, /\.svg$/);

export const icons = reqPedigreeIcons
    .keys()
    .reduce((icons, path) => {
        icons[path.split("/")[2]] = reqPedigreeIcons(path)
        return icons
    }, {});

export const getPedigreeIcon = (relationId, hasCancer = false, isDeceased = false, isSelf = false) => {
    const relationGender = relationsAndRelations[relationId].gender;
    const svgString = `${relationGender}${isDeceased ? "Deceased" : ""}${isSelf ? "Self" : ""}${hasCancer ? "Condition" : ""}.svg`;
    return icons[svgString];
}

const RelationCard = (props) => {
    const dispatch = useDispatch();
    const {deleteRelation} = useContext(FamilyTreeList);
    const {reactKey, entity, indent, multiBirthSelection, isMobile} = props;
    const {
        relationsDbPosting,
        relationsCardIsExpanded: cardsAreExpanded,
        relationsCardExpandedCount: expandedCount,
    } = useSelector(state => state.relations);
    const {
        patientAndRelationsId: prId,
        patientId: selfUserId,
        name,
        side,
        relationId,
        cancer,
        deceased,
        relationManis,
        children,
    } = entity;
    const {relation: relationLabel, side: sideLabel} = getRelationCardLabel(relationId, side);

    const [expanded, setExpanded] = React.useState(false);

    let cancerManis = [];
    let otherManis = [];
    let ancestryManis = [];
    let geneManis = [];

    if (relationManis.length > 0) {
        cancerManis = relationManis.filter((mani) => mani.manifestationLookupCategory === 1749);
        otherManis = relationManis.filter((mani) => ((mani.manifestationLookupCategory === 1752) && mani.manifestationId !== 161));
        geneManis = relationManis.filter((mani) => (mani.manifestationLookupCategory === 1752) && mani.manifestationId === 161);
        ancestryManis = relationManis.filter((mani) => mani.manifestationLookupCategory === 1748);
    }

    const icon = getPedigreeIcon(relationId, (cancer || cancerManis.length > 0), deceased, (relationId === 1));

    const checkIfDeletable = (id) => {
        id = parseInt(id);
        return !(
            id === 2 ||
            id === 3 ||
            id === 8 ||
            id === 9 ||
            id === 20 ||
            id === 21
        );
    }

    const handleExpand = (disable) => {
        setExpanded(!expanded);
        dispatch(disableContinueButton(disable));
    }

    const handleAddChild = async (isSonOrDaughter) => {
        sessionStorage.setItem('relationBuildScrollPosition', document.querySelector("#questionnaire-wrapper").scrollTop)
        await dispatch(addRelationsToDb([{
            relationId: relationsAndRelations[relationId][isSonOrDaughter],
            patientId: selfUserId,
            side: side ? side : "NA",
            motherPrId: relationsAndRelations[relationId].gender === "female" ? prId : null,
            fatherPrId: relationsAndRelations[relationId].gender === "male" ? prId : null,
            deceased: false,
        }]))
            .then(() => {
                dispatch(fetchRelations());
            })
    }

    return (
        <Card variant={"family-tree"}
              sx={{mt: 0.5, ml: indent ? indent : 0}}
              id={`relation-${reactKey}`}>
            <CardHeader
                avatar={icon &&
                    <img src={icon}
                         alt="pedigree icon"/>}
                action={
                    <Grid columnSpacing={2} container mt={2}>
                        <Grid item>
                            {(!expanded && !(expandedCount >= 1)) && <>
                                {
                                    (
                                        <Tooltip title={"Edit this relation"}>
                    <span>
                    <IconButton
                        disabled={expanded}
                        aria-label={`Edit ${name ? name : `your ${relationLabel}`}`}
                        color={name ? "secondary" : "primary"}
                        onClick={(e) => {
                            e.preventDefault();
                            handleExpand(true);
                        }}>
                    <BorderColorIcon/>
                  </IconButton>
                    </span></Tooltip>)}</>}
                        </Grid>
                        {
                            <Grid item>
                                {checkIfDeletable(relationId) ?
                                    <Tooltip title={
                                        expanded
                                            ? expandedCount > 1
                                                ? `Save/cancel changes on ${expandedCount - 1} relative${expandedCount - 1 > 1 ? "s"
                                                    : ""} before deleting this relation`
                                                : `Delete ${name ? name : `your ${relationLabel}`}?`
                                            : cardsAreExpanded
                                                ? `Save/cancel changes on ${expandedCount} relative${expandedCount > 1 ? "s" : ""} before deleting this relation`
                                                : `Delete ${name ? name : `your ${relationLabel}`}?`}>
                  <span>
                  <IconButton disabled={expanded ? expandedCount > 1 : cardsAreExpanded}
                              aria-label={`Delete ${name ? name : `your ${relationLabel}`}?`}
                              color={"secondary"}
                              onClick={(e) => {
                                  e.preventDefault();
                                  if (window.confirm("Are you sure you want to delete this relation?")) {
                                      deleteRelation(reactKey);
                                  }
                              }}>
                    <RemoveCircleOutlineIcon/>
                  </IconButton>
                  </span>
                                    </Tooltip>
                                    : undefined}
                            </Grid>
                        }
                    </Grid>
                }
                title={
                    <div>{relationLabel && `${relationLabel}${sideLabel ? "" : `${name ? ":" : ""}`}`} {isMobile ?
                        <br/> : ""}{sideLabel && `${sideLabel}${name ? ":" : ""}`} {name && name}</div>
                }
                subheader={
                    <Grid container alignItems="center" columnSpacing={2}>
                        {relationsAndRelations[relationId].sonLabel &&
                            <Grid item>
                                <Tooltip
                                    title={cardsAreExpanded
                                        ? `Save/cancel changes on ${expandedCount} relative${expandedCount > 1 ? "s" : ""} to add a new ${relationsAndRelations[relationId].sonLabel}.`
                                        : relationsDbPosting
                                            ? "Please Wait"
                                            : `Add ${relationsAndRelations[relationId].sonLabel}`}>
                  <span>
                  <Button disabled={cardsAreExpanded || relationsDbPosting} onClick={(e) => {
                      e.preventDefault();
                      // noinspection JSIgnoredPromiseFromCall
                      handleAddChild("sonId")
                  }} disableRipple variant={"undecorated"}>
                    <AddIcon fontSize={"small"}/>
                    Add {`${(relationId === 2 || relationId === 3) ? "Your " : ""}${relationsAndRelations[relationId].sonLabel}`}
                  </Button>
                  </span>
                                </Tooltip>
                            </Grid>
                        }
                        {relationsAndRelations[relationId].daughterLabel &&
                            <Grid item>
                                <Tooltip
                                    title={cardsAreExpanded
                                        ? `Save/cancel changes on ${expandedCount} relative${expandedCount > 1 ? "s" : ""} to add a new ${relationsAndRelations[relationId].daughterLabel}.`
                                        : relationsDbPosting
                                            ? "Please Wait"
                                            : `Add ${relationsAndRelations[relationId].daughterLabel}`}>
                  <span>
                    <Button disabled={cardsAreExpanded || relationsDbPosting} onClick={(e) => {
                        e.preventDefault();
                        // noinspection JSIgnoredPromiseFromCall
                        handleAddChild("daughterId")
                    }} disableRipple variant={"undecorated"}>
                  <AddIcon fontSize={"small"}/>
                  Add {`${(relationId === 2 || relationId === 3) ? "Your " : ""}${relationsAndRelations[relationId].daughterLabel}`}
                </Button>
                  </span>
                                </Tooltip>
                            </Grid>
                        }
                    </Grid>
                }
                sx={{padding: 0}}
            />
            {
                /* don't show the manifestation details if the card is expanded, but still draw a line */
                (!(relationManis && (cancerManis.length > 0 || otherManis.length > 0)) && expanded) &&
                <>
                    <hr/>
                </>
            }
            <RelationConditions
                cancerManis={cancerManis}
                otherManis={otherManis}
                expanded={expanded}
                nameOrLabel={name ? name : `Your ${relationLabel}`}/>
            {
                /*
                * if this relation has children,
                // TODO: put them into an accordion view
                * */
                ((children && children.length > 0) && !expanded) &&
                <>
                    <hr/>
                    <Typography
                        variant={"h5"}
                        textAlign={"left"}
                        sx={{fontSize: "17px !important"}}>
                        {name ? `${name}` : `Your ${relationLabel}`}'s {children.length > 1 ? "Children" : "Child"}:
                    </Typography>
                    {children.map((child, index) => {
                        return (
                            <RelationCard
                                key={child.reactId}
                                reactKey={child.reactId}
                                entity={child}
                                multiBirthSelection={children}
                                isMobile={isMobile}/>)
                    })}
                </>
            }
            <Collapse in={expanded}>
                <RelationEdit
                    reactKey={reactKey}
                    relationLabel={relationLabel}
                    sideLabel={sideLabel}
                    handleExpansion={handleExpand}
                    relationGender={relationsAndRelations[relationId].gender}
                    manifestations={
                        {
                            cancer: cancerManis,
                            other: otherManis,
                            ancestry: ancestryManis,
                            gene: geneManis
                        }
                    }
                    multiBirthSelection={multiBirthSelection}
                />
            </Collapse>
        </Card>
    )
}

export default RelationCard;